import TIM from 'tim-js-sdk'
import TIMUploadPlugin from 'tim-upload-plugin'

// 初始化 SDK 实例
const tim = TIM.create({
  SDKAppID: GLOBAL_CONFIG.adkappid
})

// 日志级别
tim.setLogLevel(GLOBAL_CONFIG.log_level)

// 注册腾讯云即时通信 IM 上传插件
tim.registerPlugin({ 'tim-upload-plugin': TIMUploadPlugin })

export default tim
