import api from '../utils/api'

// 获取签名
export async function get_user_sig () {
  // const user_id = 'user0'
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         user_id,
  //         user_sig: window.genTestUserSig(user_id).user_sig,
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v1/mp/inquiries/sdk/user_sig`)
    .then(({data})=>{
      data.user_id = data.account_id
      return {data}
    })
}

// 会话状态
export async function get_conversation_state (payload) {
  return api.post(`/api/v1/mp/inquiries/session/states`, payload)
}

// 服务器消息发送
export async function service_send_message(payload) {
  console.log(payload)
}

// 设置会话已读
export async function set_conversation_read (payload) {
  console.log(payload)
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        data: null
      })
    }, 0)
  })
  // return api.post(`/api/v1/mp/inquiries/session/read`, payload)
}
