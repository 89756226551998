import TIM from 'tim-js-sdk'
import { Notify } from 'vant'
import store from '../store'
import tim from './tim'
import { logout } from '../utils/api'
import { get_user_sig, get_conversation_state, service_send_message, set_conversation_read } from './service'

// 初始事件
// 登录成功后会触发 SDK_READY 事件，该事件触发后，可正常使用 SDK 接口
tim.on(TIM.EVENT.SDK_READY, sdk_state)
// SDK NOT READT
tim.on(TIM.EVENT.SDK_NOT_READY, sdk_state)
// 被踢出
tim.on(TIM.EVENT.KICKED_OUT, logout)
// SDK内部出错
tim.on(TIM.EVENT.ERROR, sdk_error)
// 收到新消息
tim.on(TIM.EVENT.MESSAGE_RECEIVED, receive_message)
// 会话列表更新
tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, listener_conversation_list)
// 网络监测
tim.on(TIM.EVENT.NET_STATE_CHANGE, net_state_change)
// 已读回执
tim.on(TIM.EVENT.MESSAGE_READ_BY_PEER, message_read_by_peer)

// state
export function get_state (name, path = 'im') {
  return JSON.parse(JSON.stringify(store.state[path][name]))
}

// commit
export function commit (name, payload, path = 'im') {
  store.commit(`${path}/${name}`, payload)
}

// dispatch
export async function dispatch (name, payload, path = 'im') {
  return store.dispatch(`${path}/${name}`, payload)
}

// 会话id转用户id
export function conversation_user_id (id, name = 'C2C') {
  return id.substr(name.length)
}

// 用户id转会话id
export function user_conversation_id (id, name = 'C2C') {
  return `${name}${id}`
}

// sdk状态
function sdk_state ({ name }) {
  const state = name === TIM.EVENT.SDK_READY ? true : false
  commit('update_sdk_state', state)
}

// 错误
function sdk_error ({data}) {
  if (data.message !== 'Network Error') {
    Notify({
      type: 'danger',
      message: data.message,
    })
  }
}

// 网络监测
function net_state_change ({data}) {
  let params = null
  switch (data.state) {
  case TIM.TYPES.NET_STATE_CONNECTED:
    params = { message: '已接入网络', type: 'success' }
    break
  case TIM.TYPES.NET_STATE_CONNECTING:
    params = { message: '当前网络不稳定', type: 'warning' }
    break
  case TIM.TYPES.NET_STATE_DISCONNECTED:
    params = { message: '当前网络不可用', type: 'danger' }
    break
  default:
    return
  }
  Notify(params)
}

// im登录
export async function im_login () {
  let timer = null
  try {
    const is_sdk_ready = get_state('is_sdk_ready')
    if (is_sdk_ready) return
    // 是否登录
    const is_login = get_state('is_login')
    if (!is_login) {
      commit('update_login_state', true)
      const {data} = await get_user_sig()
      commit('update_user_id', data.user_id)
      await tim.login({
        userID: data.user_id,
        userSig: data.user_sig
      })
    }

    return new Promise((resolve) => {
      timer = setInterval(() => {
        const is_sdk_ready = get_state('is_sdk_ready')
        if (is_sdk_ready) {
          if (timer) clearInterval(timer)
          resolve(null)
        }
      }, 300)
    })
  } catch (error) {
    if (timer) clearInterval(timer)
    Notify({
      type: 'danger',
      message: 'IM登录失败：' + error.message,
    })
    return Promise.reject()
  }
}

// im退出
export async function im_logout () {
  const is_sdk_ready = get_state('is_sdk_ready')
  if (is_sdk_ready) {
    await tim.logout()
  }

}

// 会话状态
async function conversation_list_state (list) {
  let sdk_users = []
  for (let item of list) {
    const conversationID = item['conversationID']
    const user_id = conversation_user_id(conversationID)
    sdk_users.push(user_id)
  }
  const {data = {}} = await get_conversation_state({sdk_users})
  for (let item of list) {
    const conversationID = item['conversationID']
    const user_id = conversation_user_id(conversationID)
    const current_state = data[user_id] || {}
    item['_state'] = current_state['state']
    item['_left_seconds'] = current_state['left_seconds'] * 1000 // 后端返回的是秒
    item['_update_time'] = new Date().getTime()
    item['_create_by'] = current_state['create_by']
  }
  commit('update_conversation_list', list)
}

// 会话更新
let timer = null
async function update_conversation_list (list) {
  if (!timer) {
    timer = true
    conversation_list_state(list)
  } else {
    if (timer && timer !== true) clearTimeout(timer)
    timer = setTimeout(() => {
      timer = null
      conversation_list_state(list)
    }, 1000)
  }
}

// 会话列表
export async function get_onversation_list (refresh = false) {
  await im_login()
  const conversation_list = get_state('conversation_list')
  if (refresh || !conversation_list || conversation_list.length === 0) {
    const {data} = await tim.getConversationList()
    update_conversation_list(data.conversationList)
  }

}

// 监听会话列表
function listener_conversation_list ({data}) {
  update_conversation_list(data)
}

// 获取会话资料
export async function get_conversation_profile (id) {
  await im_login()
  return tim.getConversationProfile(id)
}

// 消息时间分割, 1初次列表 2为新消息 3历史消息
function message_time_split(id, list, type=1) {
  if (!list || list.length === 0) return []
  const conversation_last_show_times = get_state('conversation_last_show_times')
  const new_list = []
  let last_show_time = 0
  if (type === 2) {
    last_show_time = conversation_last_show_times[id]
  }
  for (let item of list) {
    const time = item.time * 1000
    if (time - last_show_time > 300000) {
      new_list.push({
        type: "TIMCustomElem",
        payload: {
          data: {
            type: 991,
            data: {
              time
            }
          }
        }
      })
      last_show_time = time
    }
    new_list.push(item)
  }
  if (type === 1 || type === 2) {
    conversation_last_show_times[id] = last_show_time
    commit('update_conversation_last_show_times', conversation_last_show_times)
  }
  return new_list
}

// 消息格式处理
function message_list_format (list) {
  for (let item of list) {
    if (item.type === TIM.TYPES.MSG_CUSTOM) {
      let data = null
      // 兼容
      try {
        data = JSON.parse(item.payload.data)
      } catch (error) {
        data = item.payload.data
      }
      item.payload.data = data
    }
  }
  return list
}

// 获取会话
export async function get_conversation (id, refresh=false, history=true) {
  await im_login()
  let all_conversation_data = get_state('all_conversation_data')
  const count = get_state('page_size')
  let item = all_conversation_data[id]
  if (!history) {
    item = {
      id,
      message_list: [],
      next_req_message_id: null,
      is_completed: false,
    }
    // 多处可能会操作，必须同步
    all_conversation_data = get_state('all_conversation_data')
    all_conversation_data[id] = item
    commit('update_all_conversation_data', all_conversation_data)
  } else if (!item || refresh) {
    const {data} = await tim.getMessageList({
      conversationID: id,
      count
    })
    // 消息列表
    let message_list = message_list_format(data.messageList)
    message_list = message_time_split(id, message_list)
    // 分页传入字段
    const next_req_message_id = data.nextReqMessageID
    // 是否拉完，没有更多消息
    const is_completed = data.isCompleted
    item = {
      id,
      message_list,
      next_req_message_id,
      is_completed
    }
    // 多处可能会操作，必须同步
    all_conversation_data = get_state('all_conversation_data')
    all_conversation_data[id] = item
    commit('update_all_conversation_data', all_conversation_data)
  }
}

// 删除会话
export async function delete_conversation (id) {
  await im_login()
  return tim.deleteConversation(id)
}

// 拉取历史消息
export async function get_history_message(id) {
  await im_login()
  let all_conversation_data = get_state('all_conversation_data')
  const count = get_state('page_size')
  // 当前消息
  let item = all_conversation_data[id]
  const nextReqMessageID = item['next_req_message_id']
  const is_completed = item['is_completed']

  if (is_completed) return
  const {data} = await tim.getMessageList({
    conversationID: id,
    nextReqMessageID,
    count
  })
  // 多处可能会操作，必须同步
  all_conversation_data = get_state('all_conversation_data')
  item = all_conversation_data[id]
  data.messageList = message_list_format(data.messageList)
  data.messageList  = message_time_split(id, data.messageList, 3)
  item.message_list = data.messageList.concat(item.message_list)
  item.next_req_message_id = data.nextReqMessageID
  item.is_completed = data.isCompleted

  commit('update_all_conversation_data', all_conversation_data)
}

// 收到消息
function receive_message ({data: messageList}) {
  const all_conversation_data = get_state('all_conversation_data')
  for (let item of messageList) {
    const conversationID = item['conversationID']
    const item2 = all_conversation_data[conversationID]
    // 之前拉取过的会话才更新，get_conversation
    if (item2) {
      let message_list = message_list_format([item])
      message_list  = message_time_split(conversationID, message_list, 2)
      item2.message_list = item2.message_list.concat(message_list)
    }
  }
  commit('update_all_conversation_data', all_conversation_data)
}

// 已读回执
function message_read_by_peer ({data}) {
  const all_conversation_data = get_state('all_conversation_data')
  data = message_list_format(data)
  for (let item of data) {
    const id = item['ID']
    const conversationID = item['conversationID']
    const item2 = all_conversation_data[conversationID]
    if (item2) {
      item2.message_list = item2.message_list.map(item3 => {
        if (item3['ID'] === id) return item
        return item3
      })
    }
  }
  commit('update_all_conversation_data', all_conversation_data)
}

// 设置消息已读
export async function set_message_read (id) {
  await im_login()
  // 服务端需要单独设置
  await set_conversation_read({target: conversation_user_id(id)})
  return tim.setMessageRead({conversationID: id})
}

// 消息重发
export async function resend_message (message) {
  await im_login()
  return tim.resendMessage(message) // message本身会变化，对象引用会改变总值
}

// 发送文字消息
export async function send_text_message (id, text) {
  await im_login()
  let message = tim.createTextMessage({
    to: conversation_user_id(id),
    conversationType: TIM.TYPES.CONV_C2C,
    payload: {
      text
    }
  })
  try {
    const data = await tim.sendMessage(message)
    receive_message({data: [data.data.message]})
  } catch (error) {
    receive_message({data: [message]})
  }
}

// 发送图片消息
export async function send_img_message (id, file, progress = () => {}) {
  await im_login()
  let message = tim.createImageMessage({
    to: conversation_user_id(id),
    conversationType: TIM.TYPES.CONV_C2C,
    payload: {
      file
    },
    onProgress: progress
  })
  try {
    const data = await tim.sendMessage(message)
    receive_message({data: [data.data.message]})
  } catch (error) {
    receive_message({data: [message]})
  }
}

// 发送自定义消息
export async function send_custom_message (id, data) {
  await im_login()
  let message = tim.createCustomMessage({
    to: conversation_user_id(id),
    conversationType: TIM.TYPES.CONV_C2C,
    payload: {
      data: JSON.stringify(data),
      description: '',
      extension: '',
    }
  })
  try {
    const data = await tim.sendMessage(message)
    receive_message({data: [data.data.message]})
  } catch (error) {
    receive_message({data: [message]})
  }
}

// 服务器消息发送
export async function service_send_message_common (body) {
  let num = Math.random()
  let MsgRandom = 0
  if (num > 0.1) {
    MsgRandom = Math.floor(num*10000000)
  } else {
    num += 0.1
    MsgRandom = Math.floor(num*10000000)
  }
  const new_data = {
    SyncOtherMachine: 2,
    MsgRandom,
    ...body,
  }
  await service_send_message(new_data)
}

// 服务器文本消息
export async function service_send_text_message (id, text) {
  // 需要登录获取user_id
  await im_login()
  await service_send_message_common({
    From_Account: conversation_user_id(id),
    To_Account: get_state('user_id'),
    MsgBody: [
      {
        MsgType: 'TIMTextElem',
        MsgContent: {
          Text: text
        }
      }
    ]
  })
}

// 服务器自定义消息
export async function service_send_custom_message (id, data) {
  // 需要登录获取user_id
  await im_login()
  await service_send_message_common({
    From_Account: conversation_user_id(id),
    To_Account: get_state('user_id'),
    MsgBody: [
      {
        MsgType: 'TIMCustomElem',
        MsgContent: {
          Data: JSON.stringify(data),
          Desc: '',
          Ext: ''
        }
      }
    ]
  })
}
